import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import './AddRecipe.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddRecipe = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [name, setName] = useState('');
  const [ingredients, setIngredients] = useState(['']);
  const [instructions, setInstructions] = useState(['']);
  const [image, setImage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [course, setCourse] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate('/login', { state: { from: '/add-recipe' } });
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleIngredientChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index] = value;
    setIngredients(newIngredients);
  };

  const handleInstructionChange = (index, value) => {
    const newInstructions = [...instructions];
    newInstructions[index] = value;
    setInstructions(newInstructions);
  };

  const addIngredient = () => {
    setIngredients([...ingredients, '']);
  };

  const addInstruction = () => {
    setInstructions([...instructions, '']);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('You must be logged in to add a recipe.');
      navigate('/login', { state: { from: '/add-recipe' } });
      return;
    }
    if (image && !image.startsWith('data:image/')) {
      alert('Invalid image format. Please upload a valid image.');
      return;
    }
    const db = getDatabase(app);
    const storage = getStorage(app);
    const recipesRef = ref(db, 'recipes');
    try {
      let imageUrl = '';
      if (image) {
        const imageRef = storageRef(storage, `recipe-images/${Date.now()}.jpg`);
        await uploadString(imageRef, image, 'data_url');
        imageUrl = await getDownloadURL(imageRef);
      }
      const currentDate = new Date().toISOString();
      const newRecipe = {
        name,
        ingredients: ingredients.filter(ingredient => ingredient.trim() !== ''),
        instructions: instructions.filter(instruction => instruction.trim() !== ''),
        imageUrl,
        addedBy: currentUser.uid,
        dateAdded: currentDate,
        course,
      };
      const newRecipeRef = await push(recipesRef, newRecipe);
      console.log('Recipe added successfully with key:', newRecipeRef.key);
      toast.success('Recipe added successfully!');
      navigate('/recipes');
    } catch (error) {
      console.error('Error adding recipe:', error);
      toast.error(`Failed to add recipe. Error: ${error.message}`);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  if (!currentUser) {
    return null; // or a loading indicator
  }

  return (
    <div className="add-recipe">
      <h2>Add New Recipe</h2>
      <ToastContainer />
      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <input
          type="text"
          placeholder="Recipe Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

<h3>Choose Course</h3>
<div className="course-options">
  {['appetizers', 'drinks', 'salads', 'soups', 'main dish', 'side dish', 'dessert', 'other'].map((courseOption) => (
    <label key={courseOption}>
      <input
        type="radio"
        value={courseOption}
        checked={course === courseOption}
        onChange={(e) => setCourse(e.target.value)}
        required
      />
      {courseOption.charAt(0).toUpperCase() + courseOption.slice(1)}
    </label>
  ))}
</div>

        <h3>Ingredients</h3>
        {ingredients.map((ingredient, index) => (
          <input
            key={index}
            type="text"
            placeholder={`Ingredient ${index + 1}`}
            value={ingredient}
            onChange={(e) => handleIngredientChange(index, e.target.value)}
          />
        ))}
        <button type="button" onClick={addIngredient}>Add Ingredient</button>

        <h3>Instructions</h3>
        {instructions.map((instruction, index) => (
          <input
            key={index}
            type="text"
            placeholder={`Step ${index + 1}`}
            value={instruction}
            onChange={(e) => handleInstructionChange(index, e.target.value)}
          />
        ))}
        <button type="button" onClick={addInstruction}>Add Instruction</button>

        {!image && (
          <>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <button type="button" onClick={() => fileInputRef.current.click()}>Upload Picture</button>
          </>
        )}

        {image && (
          <div className="preview">
            <img src={image} alt="Recipe" />
            <button type="button" onClick={() => setImage(null)}>Remove Image</button>
          </div>
        )}

        <button type="submit">Add Recipe</button>
        <button type="button" onClick={() => navigate('/home-search')}>
          Return to Search Page
        </button>
      </form>
    </div>
  );
};

export default AddRecipe;
