import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { getDatabase, ref, onValue, set, remove, serverTimestamp} from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import StarRating from './StarRating';
import AddReview from './AddReview';
import './ProductDetail.css';

const ProductDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddReview, setShowAddReview] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const addReviewRef = useRef(null);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);

        // Fetch favorite status
        const db = getDatabase(app);
        const favRef = ref(db, `users/${user.uid}/favorites/${productId}`);
        onValue(favRef, (snapshot) => {
          setIsFavorite(snapshot.exists());
        });
      } else {
        setCurrentUser(null);
      }
    });

    const db = getDatabase(app);
    const productRef = ref(db, `nature/products/${productId}`);

    const unsubscribe = onValue(productRef, (snapshot) => {
      if (snapshot.exists()) {
        const productData = snapshot.val();
        setProduct(productData);
        setIsLoading(false);
      } else {
        setError('Product not found');
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribe();
    };
  }, [productId, refreshTrigger]);

  const handleBackToAllProducts = () => {
    navigate('/all-products', { 
      state: { scrollPosition: location.state?.scrollPosition || 0 }
    });
  };

  const handleFavoriteToggle = useCallback(() => {
    if (!currentUser) {
      navigate('/login', { state: { from: `/product/${productId}` } });
      return;
    }

    const db = getDatabase(app);
    const favRef = ref(db, `users/${currentUser.uid}/favorites/${productId}`);

    if (isFavorite) {
      remove(favRef)
        .then(() => {
          setIsFavorite(false);
          console.log("Product removed from favorites:", productId);
        })
        .catch((error) => console.error("Error removing favorite:", error));
    } else {
      set(favRef, {
        dateAdded: serverTimestamp(),
        productId: productId
      })
        .then(() => {
          setIsFavorite(true);
          console.log("Product added to favorites:", productId);
        })
        .catch((error) => console.error("Error adding favorite:", error));
    }
  }, [currentUser, productId, isFavorite, navigate]);

  const handleAddReviewClick = () => {
    if (currentUser) {
      setShowAddReview(true);
      setTimeout(() => {
        addReviewRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } else {
      navigate('/login', { state: { from: `/product/${productId}` } });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!product) {
    return <div>No product found</div>;
  }

  const sortedReviews = product.reviews
    ? Object.entries(product.reviews)
        .sort(([, a], [, b]) => (b.likes || 0) - (a.likes || 0))
    : [];

  return (
    <div className="product-detail-container">
      <h3>{product.productDescription}</h3>
      <p>Brand: {product.brand}</p>
      <p>Purchased at: {product.whereBought}</p>
      {product.imageUrl && <img src={product.imageUrl} alt={product.productDescription} className="product-image" />}
      
      <div className="product-actions">
        <button className="favorite-button" onClick={handleFavoriteToggle}>
          {isFavorite ? 'Unfavritz' : 'Favritz'} Product
        </button>
        <button className="add-review-button" onClick={handleAddReviewClick}>Add Review</button>
        <button className="back-button" onClick={handleBackToAllProducts}>Back to All Items</button>
      </div>

      <p>
        <Link to="/home-search" className="search-reviews-button">Search other sources for reviews</Link>
      </p>

      <h3>Reviews</h3>
      <div className="reviews-list">
        {sortedReviews.length > 0 ? (
          sortedReviews.map(([reviewId, review]) => (
            <div key={reviewId} className="review-card">
              <p><strong>{review.username}</strong></p>
              <p>{review.review}</p>
              <StarRating rating={review.rating} onRatingChange={() => {}} readonly size={15} />
              <p>Date: {new Date(review.date).toLocaleDateString()}</p>
            </div>
          ))
        ) : (
          <p>No reviews available</p>
        )}
      </div>
      {showAddReview && currentUser && (
        <AddReview 
          productId={productId} 
          onReviewAdded={() => {
            setShowAddReview(false);
            setRefreshTrigger(prev => prev + 1);
          }}
        />
      )}
    </div>
  );
};

export default ProductDetail;