import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDatabase, ref, push } from 'firebase/database';
import { getStorage, ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import './AddRestaurant.css';

const AddRestaurant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [name, setName] = useState(location.state?.formData?.name || '');
  const [restaurantLocation, setRestaurantLocation] = useState(location.state?.formData?.location || '');
  const [image, setImage] = useState(location.state?.capturedImage || null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate('/login', { state: { from: '/add-restaurant' } });
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      alert('You must be logged in to add a restaurant.');
      navigate('/login', { state: { from: '/add-restaurant' } });
      return;
    }

    if (image && !image.startsWith('data:image/')) {
      alert('Invalid image format. Please upload a valid image.');
      return;
    }

    const db = getDatabase(app);
    const storage = getStorage(app);
    const restaurantsRef = ref(db, 'restaurants');

    try {
      let imageUrl = '';
      if (image) {
        const imageRef = storageRef(storage, `restaurant-images/${Date.now()}.jpg`);
        await uploadString(imageRef, image, 'data_url');
        imageUrl = await getDownloadURL(imageRef);
      }

      const currentDate = new Date().toISOString();
      const newRestaurant = {
        name,
        location: restaurantLocation,
        imageUrl,
        addedBy: currentUser.uid,
        addedByUsername: currentUser.displayName,
        dateAdded: currentDate,
      };

      const newRestaurantRef = await push(restaurantsRef, newRestaurant);
      console.log('Restaurant added successfully with key:', newRestaurantRef.key);

      alert('Restaurant added successfully!');
      navigate('/all-restaurants');
    } catch (error) {
      console.error('Error adding restaurant:', error);
      alert(`Failed to add restaurant. Error: ${error.message}`);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!currentUser) {
    return null; // or a loading indicator
  }

  return (
    <div className="add-restaurant">
      <h2>Add New Restaurant</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Restaurant Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="location">Location:</label>
          <input
            type="text"
            id="location"
            value={restaurantLocation}
            onChange={(e) => setRestaurantLocation(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="image">Image:</label>
          <input
            type="file"
            id="image"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept="image/*"
          />
          {image && <img src={image} alt="Preview" style={{ width: '100px', height: '100px' }} />}
        </div>
        <input
          type="text"
          placeholder="Restaurant Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Location"
          value={restaurantLocation}
          onChange={(e) => setRestaurantLocation(e.target.value)}
          required
        />
        
        {!image && (
          <>
            <button type="button" onClick={() => navigate('/take-picture-restaurant', { state: { formData: { name, location: restaurantLocation } } })}>Take Picture</button>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <button type="button" onClick={() => fileInputRef.current.click()}>Upload Existing Picture</button>
          </>
        )}
        
        {image && (
          <div className="preview">
            <img src={image} alt="Captured restaurant" />
            <button type="button" onClick={() => setImage(null)}>Remove Image</button>
          </div>
        )}
        
        <button type="submit">Add Restaurant</button>
      </form>
    </div>
  );
};

export default AddRestaurant;
