import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue, push } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import './RecipeDetail.css';
import TopNavBar from './TopNavBar';
import StarRating from './StarRating';

const RecipeDetail = () => {
  const { recipeId } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = getDatabase(app);
    const recipeRef = ref(db, `recipes/${recipeId}`);
    const reviewsRef = ref(db, `recipes/${recipeId}/reviews`);

    onValue(recipeRef, (snapshot) => {
      setRecipe(snapshot.val());
    });

    onValue(reviewsRef, (snapshot) => {
      const data = snapshot.val();
      const allReviews = data ? Object.entries(data) : [];
      setReviews(allReviews);
      setIsLoading(false);
    });
  }, [recipeId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('You must be logged in to add a review.');
      return;
    }

    if (rating === 0) {
      setWarningMessage('Please add your star rating');
      return;
    }

    const db = getDatabase(app);
    const reviewsRef = ref(db, `recipes/${recipeId}/reviews`);
    push(reviewsRef, {
      review,
      rating,
      date: new Date().toISOString(),
      addedByUsername: currentUser.displayName,
    }).then(() => {
      setReview('');
      setRating(0);
      setWarningMessage('');
      alert('Review added successfully!');
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!recipe) {
    return <div>No recipe found</div>;
  }

  const sortedReviews = reviews.sort(([, a], [, b]) => new Date(b.date) - new Date(a.date));

  return (
    <div className="recipe-detail-page">
      <TopNavBar />
      <div className="recipe-detail-container">
        <h2>{recipe.name}</h2>
        {recipe.imageUrl && <img src={recipe.imageUrl} alt={recipe.name} className="recipe-image" />}
        <h3>Ingredients:</h3>
        <ul>
          {recipe.ingredients.map((ingredient, index) => (
            <li key={index}>{ingredient}</li>
          ))}
        </ul>
        <h3>Instructions:</h3>
        <ol>
          {recipe.instructions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ol>
        <p>Added by: {recipe.addedByUsername}</p>
        <p>Date Added: {new Date(recipe.dateAdded).toLocaleDateString()}</p>
        
        <button className="back-button" onClick={() => navigate('/recipes')}>Back to All Recipes</button>
        <button className="edit-button" onClick={() => navigate(`/edit-recipe/${recipeId}`)}>Edit Recipe</button>

        <h3>Reviews</h3>
        <div className="reviews-list">
          {sortedReviews.length > 0 ? (
            sortedReviews.map(([reviewId, review]) => (
              <div key={reviewId} className="review-card">
                <p><strong>{review.addedByUsername || 'Anonymous'}</strong></p>
                <p>{review.review}</p>
                <StarRating rating={review.rating} readonly size={15} />
                <p>Date: {new Date(review.date).toLocaleDateString()}</p>
              </div>
            ))
          ) : (
            <p>No reviews available</p>
          )}
        </div>

        <h3>Add Your Review</h3>
        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <div className="form-group">
            <label htmlFor="review">Your Review:</label>
            <textarea
              id="review"
              value={review}
              onChange={(e) => setReview(e.target.value)}
              placeholder="Write your review here..."
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="rating">Rating:</label>
            <StarRating rating={rating} onRatingChange={setRating} size={30} />
          </div>
          {warningMessage && <p className="warning-message">{warningMessage}</p>}
          <div className="button-group">
            <button type="submit">Submit Review</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecipeDetail;
