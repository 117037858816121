import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDatabase, ref, push, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import StarRating from './StarRating';
import './AddReview.css';

const AddReview = () => {
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [hasReviewed, setHasReviewed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { productId } = useParams();
  const navigate = useNavigate();

  const checkExistingReview = useCallback(async (uid) => {
    if (!uid) return false;
    
    const db = getDatabase(app);
    const reviewsRef = ref(db, `nature/products/${productId}/reviews`);
    const snapshot = await get(reviewsRef);
    
    if (snapshot.exists()) {
      const reviews = snapshot.val();
      return Object.values(reviews).some(review => review.userId === uid);
    }
    return false;
  }, [productId]);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUsername(user.displayName || user.email);
        setUserId(user.uid);
        const reviewExists = await checkExistingReview(user.uid);
        setHasReviewed(reviewExists);
        if (reviewExists) {
          alert('You have already submitted a review for this product.');
          navigate(`/product/${productId}`);
        }
      } else {
        setUsername('');
        setUserId('');
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, [checkExistingReview, navigate, productId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0) {
      alert('Please select a rating');
      return;
    }

    // Check again right before submission
    const reviewExists = await checkExistingReview(userId);
    if (reviewExists) {
      alert('You have already submitted a review for this product.');
      navigate(`/product/${productId}`);
      return;
    }

    const db = getDatabase(app);
    const reviewsRef = ref(db, `nature/products/${productId}/reviews`);
    const reviewData = {
      review,
      rating,
      date: new Date().toISOString(),
      likes: 0,
      timestamp: Date.now(),
      username,
      userId,
      productId // Ensure productId is included
    };

    try {
      await push(reviewsRef, reviewData);
      alert('Review added successfully!');
      navigate(`/product/${productId}`);
    } catch (error) {
      console.error('Error adding review:', error);
      alert('Failed to add review. Please try again.');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (hasReviewed) {
    return null;  // This will prevent rendering anything if the user has already reviewed
  }

  return (
    <div className="add-review">
      <h3>Add Your Review</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="rating">Rating:</label>
          <StarRating rating={rating} onRatingChange={setRating} />
        </div>
        <div className="form-group">
          <label htmlFor="review">Your Review:</label>
          <textarea
            id="review"
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Write your review here..."
            required
          />
        </div>
        <div className="button-group">
          <button type="submit">Submit Review</button>
        </div>
      </form>
    </div>
  );
};

export default AddReview;
