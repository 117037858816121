import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import app from './firebaseConfig';
import './Recipes.css';
import TopNavBar from './TopNavBar';
import StarRating from './StarRating';

const Recipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);
    const recipesRef = ref(db, 'recipes');
    const usersRef = ref(db, 'users');

    onValue(usersRef, (snapshot) => {
      const usersData = snapshot.val();
      setUsers(usersData || {});
    });

    onValue(recipesRef, (snapshot) => {
      const data = snapshot.val();
      const allRecipes = [];

      for (const recipeId in data) {
        const recipe = data[recipeId];
        let averageRating = 0;
        let reviewCount = 0;
        if (recipe.reviews) {
          const ratings = Object.values(recipe.reviews).map((review) => Number(review.rating));
          averageRating = ratings.reduce((a, b) => a + b, 0) / ratings.length;
          reviewCount = ratings.length;
        }
        allRecipes.push({ ...recipe, recipeId, averageRating, reviewCount });
      }

      // Sort recipes by dateAdded in descending order
      allRecipes.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));

      setRecipes(allRecipes);
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching data:', error);
      setError(error);
      setIsLoading(false);
    });
  }, []);

  const handleRecipeClick = (recipeId) => {
    navigate(`/recipe/${recipeId}`);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Filtered recipes based on selected category
  const filteredRecipes = selectedCategory === 'all' 
    ? recipes 
    : recipes.filter(recipe => recipe.course === selectedCategory);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading recipes: {error.message}</div>;
  }

  return (
    <div className="recipes-page">
      <TopNavBar />
      <h2>Recipes</h2>
      <select className="select-category" value={selectedCategory} onChange={handleCategoryChange}>
        <option value="all">Show all</option>
        <option value="appetizers">Appetizers</option>
        <option value="drinks">Drinks</option>
        <option value="salads">Salads</option>
        <option value="soups">Soups</option>
        <option value="main dish">Main Dish</option>
        <option value="side dish">Side Dish</option>
        <option value="dessert">Dessert</option>
        <option value="other">Other</option>
      </select>
      <button className="add-recipe-button" onClick={() => navigate('/add-recipe')}>
        Add New Recipe
      </button>
      <div className="recipes-list">
        {filteredRecipes.map((recipe) => (
          <div key={recipe.recipeId} className="recipe-card" onClick={() => handleRecipeClick(recipe.recipeId)}>
            {recipe.imageUrl ? (
              <img src={recipe.imageUrl} alt={recipe.name} />
            ) : (
              <div className="no-image">No picture available</div>
            )}
            <h3>{recipe.name}</h3>
            <p>{recipe.ingredients[0]}...</p>
            <StarRating rating={recipe.averageRating} readonly size={15} />
            <p>{recipe.reviewCount} review{recipe.reviewCount !== 1 ? 's' : ''}</p>
            <p>Added by: {users[recipe.addedBy]?.username || 'Anonymous'}</p>
            <p>Date Added: {new Date(recipe.dateAdded).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recipes;
