import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaSearch, FaInfoCircle, FaEnvelope, FaUtensils, FaBook, FaQuestionCircle } from 'react-icons/fa';
import './TopNavBar.css';

const TopNavBar = () => {
  const navigate = useNavigate();

  return (
    <div className="top-nav-bar">
      <div className="nav-items">
        <div className="nav-item" onClick={() => navigate('/home-search')} style={{ color: 'green' }}>
          <FaSearch size={20} />
          <span>Search Page</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/all-products')}>
          <FaHome size={20} />
          <span>Food Items</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/restaurants')}>
          <FaUtensils size={20} />
          <span>Restaurants</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/recipes')}>
          <FaBook size={20} />
          <span>Recipes</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/faq')}>
          <FaQuestionCircle size={20} />
          <span>FAQ</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/about')}>
          <FaInfoCircle size={20} />
          <span>About Us</span>
        </div>
        <div className="nav-item" onClick={() => navigate('/contact')}>
          <FaEnvelope size={20} />
          <span>Contact</span>
        </div>
      </div>
    </div>
  );
};

export default TopNavBar;