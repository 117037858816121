import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import app from './firebaseConfig';
import './AdminProducts.css';

const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);
    const productsRef = ref(db, 'nature/products');

    const unsubscribe = onValue(productsRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const productsArray = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setProducts(productsArray);
      } else {
        setError('No products found');
      }
      setIsLoading(false);
    }, (error) => {
      setError(error.message);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDeleteProduct = (productId) => {
    const db = getDatabase(app);
    const productRef = ref(db, `nature/products/${productId}`);
    remove(productRef).then(() => {
      setProducts(products.filter(product => product.id !== productId));
    }).catch((error) => {
      console.error('Error deleting product:', error);
    });
  };

  const handleDeleteReview = (productId, reviewId) => {
    const db = getDatabase(app);
    const reviewRef = ref(db, `nature/products/${productId}/reviews/${reviewId}`);
    remove(reviewRef).then(() => {
      setProducts(products.map(product => {
        if (product.id === productId) {
          const updatedReviews = { ...product.reviews };
          delete updatedReviews[reviewId];
          return { ...product, reviews: updatedReviews };
        }
        return product;
      }));
    }).catch((error) => {
      console.error('Error deleting review:', error);
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="admin-products-container">
      <h2>Admin Products</h2>
      <button className="back-button" onClick={() => navigate('/all-products')}>Back to All Products</button>
      <button className="admin-recipes-button" onClick={() => navigate('/admin-recipes')}>Go to Admin Recipes</button>
      <div className="products-list">
        {products.map(product => (
          <div key={product.id} className="product-card">
            <h3>{product.productName}</h3>
            <p>Brand: {product.brand}</p>
            <p>Barcode: {product.barcode}</p>
            <p>Where Bought: {product.whereBought}</p>
            <button className="delete-product-btn" onClick={() => handleDeleteProduct(product.id)}>Delete Product</button>
            {product.reviews && Object.keys(product.reviews).length > 0 ? (
              <div className="reviews-list">
                <h4>Reviews:</h4>
                {Object.entries(product.reviews).map(([reviewId, review]) => (
                  <div key={reviewId} className="review-card">
                    <p><strong>{review.username}</strong></p>
                    <p>{review.review}</p>
                    <p>Rating: {review.rating}</p>
                    <p>Likes: {review.likes || 0}</p>
                    <p>Date: {new Date(review.date).toLocaleDateString()}</p>
                    <button className="delete-btn" onClick={() => handleDeleteReview(product.id, reviewId)}>Delete Review</button>
                  </div>
                ))}
              </div>
            ) : (
              <p>No reviews available</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminProducts;