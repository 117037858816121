import React, { useState, useEffect } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import './AddRestaurantReview.css';

const AddRestaurantReview = ({ restaurantId, onReviewAdded }) => {
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const db = getDatabase(app);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('You must be logged in to add a review.');
      return;
    }

    const reviewsRef = ref(db, `restaurants/${restaurantId}/reviews`);
    push(reviewsRef, {
      review,
      rating,
      date: new Date().toISOString(),
      addedByUsername: currentUser.displayName, // Include the username
    }).then(() => {
      setReview('');
      setRating(0);
      onReviewAdded();
    });
  };

  return (
    <div className="add-review-form">
      <h3>Add Review</h3>
      <form onSubmit={handleSubmit}>
        <textarea
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Write your review here"
          required
        />
        <input
          type="number"
          value={rating}
          onChange={(e) => setRating(Number(e.target.value))}
          placeholder="Rating (0-5)"
          min="0"
          max="5"
          required
        />
        <button type="submit">Submit Review</button>
      </form>
    </div>
  );
};

export default AddRestaurantReview;
