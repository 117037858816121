import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getDatabase, ref, query, onValue, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import StarRating from './StarRating';
//import logo from './review-explorer-logo.png';
import './AllProducts.css';
import TopNavBar from './TopNavBar';
import BottomNav from './BottomNav';
import { FaQuestionCircle } from 'react-icons/fa';

const AllProducts = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  const [filteredProductCount, setFilteredProductCount] = useState(0);
  const [filters, setFilters] = useState({
    description: '',
    brand: '',
    whereBought: '',
    starRating: 0,
    showFollowingOnly: false,
    followingUsers: [],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const mainContentRef = useRef(null);
  const auth = getAuth(app);
  const db = getDatabase(app);

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log('Current user:', currentUser);
    });

    const productsRef = ref(db, 'nature/products');
    const productsQuery = query(productsRef);

    onValue(productsQuery, async (snapshot) => {
      const data = snapshot.val();
      const allProducts = [];
      const userIds = new Set();

      for (const productId in data) {
        const product = data[productId];
        let averageRating = 0;
        if (product.reviews) {
          const ratings = Object.values(product.reviews).map((review) => Number(review.rating));
          averageRating = ratings.reduce((a, b) => a + b, 0) / ratings.length;
        }
        allProducts.push({ ...product, productId, averageRating, likes: product.likes || 0 });
        userIds.add(product.addedBy);
      }

      const usernames = await fetchUsernames([...userIds]);
      const favoritesCountsData = await fetchFavoritesCounts();

      const productsWithUsernames = allProducts.map(product => ({
        ...product,
        addedByUsername: usernames[product.addedBy] || 'Anonymous',
        favoriteCount: favoritesCountsData[product.productId] || 0
      }));

      productsWithUsernames.sort((a, b) => {
        const dateA = new Date(a.dateAdded);
        const dateB = new Date(b.dateAdded);
        return dateB - dateA;
      });

      setProducts(productsWithUsernames);
      setFilteredProducts(productsWithUsernames);
      setTotalProducts(productsWithUsernames.length);
      setFilteredProductCount(productsWithUsernames.length);
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching data:', error);
      setError(error);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth, db]);

  useEffect(() => {
    const fetchFollowingUsers = async () => {
      if (user) {
        const followingRef = ref(db, `follows/${user.uid}`);
        const snapshot = await get(followingRef);
        const followingUsers = [user.uid, ...Object.keys(snapshot.val() || {})];
        console.log('Fetched following users:', followingUsers);
        setFilters((prevFilters) => ({
          ...prevFilters,
          followingUsers: [...new Set(followingUsers)],
        }));

        console.log('Updated filters with following users:', followingUsers);
      }
    };

    fetchFollowingUsers();
  }, [user, db]);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const matchesDescription = (product.productDescription || '').toLowerCase().includes(filters.description.toLowerCase());
      const matchesBrand = (product.brand || '').toLowerCase().includes(filters.brand.toLowerCase());
      const matchesWhereBought = (product.whereBought || '').toLowerCase().includes(filters.whereBought.toLowerCase());
      const matchesStarRating = filters.starRating === 0 || (product.averageRating || 0) >= filters.starRating;

      let matchesFollowing = true;
      if (filters.showFollowingOnly && filters.followingUsers) {
        matchesFollowing = filters.followingUsers.includes(product.addedBy);
        console.log(`Product added by: ${product.addedBy}, Matches following: ${matchesFollowing}`);
        console.log(`Following users: ${filters.followingUsers.join(', ')}`);
      }

      return matchesDescription && matchesBrand && matchesWhereBought && matchesStarRating && matchesFollowing;
    });
    setFilteredProducts(filtered);
    setFilteredProductCount(filtered.length);
  }, [filters, products]);

  useEffect(() => {
    if (location.state && location.state.scrollPosition) {
      setTimeout(() => {
        if (mainContentRef.current) {
          mainContentRef.current.scrollTop = location.state.scrollPosition;
        }
      }, 100);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && location.state.filters) {
      setFilters(prevFilters => ({
        ...prevFilters,
        description: location.state.filters.productDescription || '',
        brand: location.state.filters.brand || '',
        whereBought: location.state.filters.whereBought || ''
      }));
    }
  }, [location.state]);

  const clearFilters = () => {
    setFilters({
      description: '',
      brand: '',
      whereBought: '',
      starRating: 0,
      showFollowingOnly: false,
      followingUsers: []
    });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const navigateToProduct = (productId) => {
    const scrollPosition = mainContentRef.current ? mainContentRef.current.scrollTop : 0;
    navigate(`/product/${productId}`, { state: { scrollPosition } });
  };

  const handleInputBlur = (e) => {
    e.target.style.fontSize = '0.9em';
    e.target.style.padding = '6px';
  };

  const handleStarRatingChange = (rating) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      starRating: rating
    }));
  };

  const fetchFavoritesCounts = async () => {
    const db = getDatabase(app);
    const usersRef = ref(db, 'users');
    const snapshot = await get(usersRef);
    const favoritesCountsData = {};

    if (snapshot.exists()) {
      snapshot.forEach((userSnapshot) => {
        const userData = userSnapshot.val();
        if (userData.favorites) {
          Object.keys(userData.favorites).forEach((productId) => {
            favoritesCountsData[productId] = (favoritesCountsData[productId] || 0) + 1;
          });
        }
      });
    }

    return favoritesCountsData;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading products: {error.message}</div>;
  }

  return (
    <div className={`all-products-page ${user ? 'logged-in' : ''}`}>
      {user && (
        <TopNavBar currentUser={user}>
          <Link to="/faq" className="faq-icon">
            <FaQuestionCircle size={24} />
          </Link>
        </TopNavBar>
      )}
      <div className="main-content" ref={mainContentRef}>
   


        
        <div className="action-buttons">
          <button className="add-product-button" onClick={() => navigate('/add-product')}>Add New Product</button>
          <button className="search-other-sources-button" onClick={() => navigate('/home-search')}>Search Other Sources</button>
        </div>
        
        <div className="filter-section">
          <p>Filter our database to narrow it down</p>
          <input
            className="filter-input"
            type="text"
            name="description"
            placeholder="Filter by description"
            value={filters.description}
            onChange={handleFilterChange}
            onBlur={handleInputBlur}
          />
          <input
            className="filter-input"
            type="text"
            name="brand"
            placeholder="Filter by brand"
            value={filters.brand}
            onChange={handleFilterChange}
            onBlur={handleInputBlur}
          />
          <input
            className="filter-input"
            type="text"
            name="whereBought"
            placeholder="Filter by where seen"
            value={filters.whereBought}
            onChange={handleFilterChange}
            onBlur={handleInputBlur}
          />
          <div className="star-rating-filter">
            <StarRating rating={filters.starRating} onRatingChange={handleStarRatingChange} />
          </div>
          <label>
            <input
              type="checkbox"
              name="showFollowingOnly"
              checked={filters.showFollowingOnly}
              onChange={(e) => setFilters(prevFilters => ({
                ...prevFilters,
                showFollowingOnly: e.target.checked
              }))}
            />
            Show Following Only
          </label>
          <button className="clear-filters-button" onClick={clearFilters}>Clear Filters</button>
        </div>
        
        <div className="filter-count">
          <p>
            <span className="product-count">{filteredProductCount}</span> item{filteredProductCount !== 1 ? 's' : ''} found.
          </p>
          <p className="product-counter">
            <span className="product-count">{totalProducts}</span> items in our database so far! Add your items to our growing list!
          </p>
        </div>
        
        <div className="products-grid">
          {filteredProducts.map((product, index) => (
            <div key={index} className="product-card">
              <div className="product-image-container" onClick={() => navigateToProduct(product.productId)}>
                {product.imageUrl && <img src={product.imageUrl} alt="Product" className="product-image" />}
              </div>
              <p className="click-to-view"><strong>Click picture</strong> to view reviews</p>
              <div className="product-details">
                {product.productDescription && <h3>{product.productDescription}</h3>}
                {product.brand && <p>Brand: {product.brand}</p>}
                {product.whereBought && <p>Where seen: {product.whereBought}</p>}
                <StarRating rating={product.averageRating || 0} onRatingChange={() => {}} readonly size={15} />
                <p className="added-info">
                  Added on: {new Date(product.dateAdded).toLocaleDateString()}
                  <br />
                  By: 
                  <Link 
                    to={`/user-products/${encodeURIComponent(product.addedByUsername || 'Anonymous')}`} 
                    state={{ showFavorites: true }}
                    className="user-link"
                  >
                    <span>{product.addedByUsername}</span>
                  </Link>
                </p>
                <p className="favorite-count">
                  Favritzed <span className="favorite-number">{product.favoriteCount}</span> time{product.favoriteCount !== 1 ? 's' : ''}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <BottomNav 
        unreadNotificationsCount={0} 
        onAdminClick={() => navigate('/admin/products')}
      />
    </div>
  );
};

const fetchUsernames = async (userIds) => {
  const db = getDatabase(app);
  const usernames = {};

  for (const userId of userIds) {
    const userRef = ref(db, `users/${userId}`);
    const snapshot = await get(userRef);
    if (snapshot.exists()) {
      usernames[userId] = snapshot.val().username;
    }
  }

  return usernames;
};

export default AllProducts;