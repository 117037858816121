// FAQ.js
import React from 'react';
import { Link } from 'react-router-dom';
import './FAQ.css';
import logo from './review-explorer-logo.png';

function FAQ() {
    return (
        <div className="faq-page">
            <div className="header-container">
                <div className="logo-container">
                    <img src={logo} alt="Review Explorer Logo" className="logo" />
                </div>
                <div className="header-text">
                    <h1>Welcome to Favritz</h1>
                    <p>Create and share favorite recipes, reviews of recipes, <strong>good or bad,</strong> grocery products, and restaurants with your friends and the world! See something you really like? <strong>Favritz it by clicking the Favritz button!</strong></p>
                </div>
            </div>
            <div className="faq-container">
                <h1>Frequently Asked Questions</h1>
                <Link to="/recipes" className="back-button">Go to Recipes Page</Link>
                <div className="faq-item">
                    <h2>What can this app do?</h2>
                    <p>Now you can easily save all of your favorite recipes in one place and find them at the click of a button! <strong>You can also view other people's favorite recipes and see what people think about them!</strong> You can add your own review of a recipe. New recipes and reviews are added daily from people just like you! Get started <Link to="/login"><strong>here</strong></Link>.</p>
                    
                <h2>How do I search the recipe database?</h2>
                <p>
                    On the recipes page, you will see the files in the database. To narrow these down, <strong>select the category from the drop-down menu.</strong> </p>
             
                <h2>How do I add a new recipe?</h2>
                <p>
                Go to the <Link to="/add-recipe"><strong>Add New Recipe</strong></Link>.  There you can add ingredients, instructions, and a picture of the recipe if you have it 
                </p>
                <h2>How do I view reviews?</h2>
                <p>Click on the picture of any item found in the database.  If a review exists you will be able to read it.  If not, consider adding your own review.
                </p>

                    <h2>How do I add a review?</h2>
                <p>
  If you see a recipe picture you like, click on it to see the recipe.  Scroll to the bottom and you will see a button to addd a review.  Click on Add Review and follow the steps. Be sure to include a star rating!
                </p>  
                <h2>Can I review my own item?</h2>
                <p>
   Yes! Try not to make the reviews too long though.  Every review has the username of the person making it so it will be obvious is someone makes a whole lot of reviews of their own listing.  It also contains the date the review was submitted.  <strong>Any objectionable reviews or listings will be deleted.</strong>
                </p>  
                <h2>What else can I do with the app?</h2>
                <p>
You can add restaurants and review restaurants.  You can also use your phone as a barcode scanner and find products on store shelves that might be in our database.  There you can see reviews about them or add your own review.  You can also add new products to the database.  In addition you can search the web and other sources for product reviews.  Try clicking the buttons on the top Navigation bar!
                </p>
  
            </div>
            </div>
            </div>
        
    );
}

export default FAQ;