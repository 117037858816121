import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import './TakePicture.css';

const TakePictureRestaurant = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const formData = location.state?.formData || {};

  useEffect(() => {
    let stream;
    const startCamera = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing the camera", err);
        alert("Unable to access the camera. Please make sure you've granted the necessary permissions.");
      }
    };

    startCamera();

    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      const imageDataUrl = canvasRef.current.toDataURL('image/jpeg');
      const imageBlob = dataURLToBlob(imageDataUrl);
      resizeImage(imageBlob);
      if (videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    }
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const resizeImage = (imageBlob) => {
    Resizer.imageFileResizer(
      imageBlob,
      300, // max width
      300, // max height
      'JPEG',
      100,
      0,
      (uri) => {
        setImage(uri);
      },
      'base64'
    );
  };

  const handleUpload = () => {
    if (image) {
      navigate('/add-restaurant', { state: { capturedImage: image, formData } });
    }
  };

  const handleCancel = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
    navigate('/add-restaurant', { state: { formData } });
  };

  return (
    <div className="take-picture">
      <h2>Take Picture</h2>
      <div className="camera">
        <video ref={videoRef} autoPlay playsInline style={{ width: '100%', height: 'auto', display: 'block' }} />
      </div>
      <div className="button-container">
        {!image && <button type="button" onClick={captureImage}>Capture</button>}
        {image && (
          <>
            <button type="button" onClick={handleUpload}>Upload</button>
            <button type="button" onClick={() => setImage(null)}>Retake</button>
          </>
        )}
        <button type="button" onClick={handleCancel}>Cancel</button>
      </div>
      {image && (
        <div className="preview">
          <img src={image} alt="Captured" />
        </div>
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default TakePictureRestaurant;
