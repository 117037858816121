import React from 'react';
import { Link } from 'react-router-dom';
import { FaBarcode, FaMicrophone, FaKeyboard, FaGoogle, FaPlus, FaUtensils, FaStar } from 'react-icons/fa';
import './HomeSearchPage.css';
import reviewExplorerLogo from './review-explorer-logo.png';

const HomeSearchPage = () => {
  return (
    <div className="home-search-page">
      <div className="home-page">
        <div className="logo-container">
          <img src={reviewExplorerLogo} alt="Review Explorer Logo" className="review-explorer-logo" />
          <div className="logo-text">
            <p className="staggered-text">Search Recipes, Foods, and Restaurants</p>
            <p className="staggered-text">Review Recipes, Foods, and Restaurants</p>
            <p className="staggered-text">Add Recipes, Foods, and Restaurants</p>
          </div>
        </div>
        <h2 className="welcome-text">Welcome to Favritz!</h2>
        <p className="intro-text">
          Create, share, and view reviews of your favorite <strong>foods, restaurants, and recipes!</strong> Look in our database or search the web for millions of reviews!
          <br />
          Click one of the buttons below to get started!
        </p>
        
        <div className="button-grid">
          <h3 className="button-section-header">Barcode Scan to View or Add Products</h3>
          <Link to="/scan-database" className="search-button barcode">
            <FaBarcode className="button-icon" />
            <span>Scan Products</span>
          </Link>
          <Link to="/scan-google" className="search-button google">
            <FaGoogle className="button-icon" />
            <span>Google Barcode Scan</span>
          </Link>
          
          <h3 className="button-section-header">Web Search</h3>
          <Link to="/voice-search" className="search-button voice">
            <FaMicrophone className="button-icon" />
            <span>Voice Web Search</span>
          </Link>
          <Link to="/text-search" className="search-button text">
            <FaKeyboard className="button-icon" />
            <span>Text Web Search</span>
          </Link>
          
          <h3 className="button-section-header">Add New Items</h3>
          <Link to="/add-product" className="search-button add-product">
            <FaPlus className="button-icon" />
            <span>Add Item to Our Database</span>
          </Link>
          <Link to="/add-recipe" className="search-button add-recipe">
            <FaUtensils className="button-icon" />
            <span>Add Recipe</span>
          </Link>
          <Link to="/add-restaurant" className="search-button add-restaurant">
            <FaStar className="button-icon" />
            <span>Add Restaurant</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeSearchPage;
