import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue, push } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import StarRating from './StarRating';
import './RestaurantDetail.css';
import TopNavBar from './TopNavBar';

const RestaurantDetail = () => {
  const { restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = getDatabase(app);
    const restaurantRef = ref(db, `restaurants/${restaurantId}`);
    const reviewsRef = ref(db, `restaurants/${restaurantId}/reviews`);

    onValue(restaurantRef, (snapshot) => {
      setRestaurant(snapshot.val());
    });

    onValue(reviewsRef, (snapshot) => {
      const data = snapshot.val();
      const allReviews = data ? Object.entries(data) : [];
      setReviews(allReviews);
      setIsLoading(false);
    });
  }, [restaurantId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('You must be logged in to add a review.');
      return;
    }

    const db = getDatabase(app);
    const reviewsRef = ref(db, `restaurants/${restaurantId}/reviews`);
    push(reviewsRef, {
      review,
      rating,
      date: new Date().toISOString(),
      addedByUsername: currentUser.displayName, // Include the username
    }).then(() => {
      setReview('');
      setRating(0);
    });
  };

  const handleFavoriteToggle = () => {
    setIsFavorite(!isFavorite);
    // Add logic to update the favorite status in the database
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!restaurant) {
    return <div>No restaurant found</div>;
  }

  const sortedReviews = reviews.sort(([, a], [, b]) => new Date(b.date) - new Date(a.date));

  return (
    <div className="restaurant-detail-page">
      <TopNavBar />
      <div className="restaurant-detail-container">
        <h3>{restaurant.name}</h3>
        <p>Location: {restaurant.location}</p>
        {restaurant.imageUrl && <img src={restaurant.imageUrl} alt={restaurant.name} className="restaurant-image" />}
        <p>Added by: {restaurant.addedByUsername}</p>
        <p>Date Added: {new Date(restaurant.dateAdded).toLocaleDateString()}</p>
        
        <div className="restaurant-actions">
          <button className="favorite-button" onClick={handleFavoriteToggle}>
            {isFavorite ? 'Unfavritz' : 'Favritz'} Restaurant
          </button>
          <button className="back-button" onClick={() => navigate('/restaurants')}>Back to All Restaurants</button>
        </div>

        <h3>Reviews</h3>
        <div className="reviews-list">
          {sortedReviews.length > 0 ? (
            sortedReviews.map(([reviewId, review]) => (
              <div key={reviewId} className="review-card">
                <p><strong>{review.addedByUsername || 'Anonymous'}</strong></p>
                <p>{review.review}</p>
                <StarRating rating={review.rating} readonly size={15} />
                <p>Date: {new Date(review.date).toLocaleDateString()}</p>
              </div>
            ))
          ) : (
            <p>No reviews available</p>
          )}
        </div>

        <h3>Add Your Review</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="review">Your Review:</label>
            <textarea
              id="review"
              value={review}
              onChange={(e) => setReview(e.target.value)}
              placeholder="Write your review here..."
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="rating">Rating:</label>
            <StarRating rating={rating} onRatingChange={setRating} />
          </div>
          <div className="button-group">
            <button type="submit">Submit Review</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RestaurantDetail;
