import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import './BottomNav.css';
import { FaUser, FaHeart, FaSignOutAlt, FaBell, FaSignInAlt, FaUsers, FaUserFriends, FaLock } from 'react-icons/fa';

const BottomNav = ({ unreadNotificationsCount, onAdminClick }) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getDatabase();
  const [unreadCount, setUnreadCount] = useState(unreadNotificationsCount);
  const [user, setUser] = useState(null);
  const [showAdminInput, setShowAdminInput] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    if (user) {
      const notificationsRef = ref(db, `notifications/${user.uid}`);
      const unsubscribe = onValue(notificationsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const unread = Object.values(data).filter(notification => !notification.read).length;
          setUnreadCount(unread);
        } else {
          setUnreadCount(0);
        }
      });

      return () => unsubscribe();
    }
  }, [user, db]);

  useEffect(() => {
    setUnreadCount(unreadNotificationsCount);
  }, [unreadNotificationsCount]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleAdminClick = () => {
    setShowAdminInput(true);
  };

  const handleAdminSubmit = (e) => {
    e.preventDefault();
    if (adminPassword === 'scoping1') {
      navigate('/admin/products');
    } else {
      alert('Incorrect password. Please try again.');
    }
    setAdminPassword('');
    setShowAdminInput(false);
  };

  return (
    <div className="bottom-nav">
      <div className="nav-item" onClick={() => navigate('/profile')}>
        <FaUser size={20} />
        <span>Profile</span>
      </div>
      <div className="nav-item" onClick={() => navigate('/favorites')}>
        <FaHeart size={20} />
        <span>Favorites</span>
      </div>
      <div className="nav-item" onClick={() => navigate('/user-list')}>
        <FaUsers size={20} />
        <span>User List</span>
      </div>
      <div className="nav-item" onClick={() => navigate('/following')}>
        <FaUserFriends size={20} />
        <span>Following</span>
      </div>
      {user && (
        <div className="nav-item" onClick={() => navigate('/notifications')}>
          <FaBell size={20} className={unreadCount > 0 ? 'has-unread' : ''} />
          <span>Notifications</span>
          {unreadCount > 0 && (
            <div className="notification-badge">{unreadCount}</div>
          )}
        </div>
      )}
      {user ? (
        <div className="nav-item" onClick={handleLogout}>
          <FaSignOutAlt size={20} />
          <span>Logout</span>
        </div>
      ) : (
        <div className="nav-item" onClick={() => navigate('/login')}>
          <FaSignInAlt size={20} />
          <span>Login</span>
        </div>
      )}
      <div className="nav-item" onClick={handleAdminClick}>
        <FaLock size={20} />
        <span>Admin</span>
      </div>
      {showAdminInput && (
        <form onSubmit={handleAdminSubmit} className="admin-form">
          <input
            type="password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            placeholder="Enter admin password"
          />
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
};

export default BottomNav;