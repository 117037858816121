import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, set, get, update } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const FollowButton = ({ targetUserId }) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      const db = getDatabase();
      const followRef = dbRef(db, `follows/${currentUser.uid}/${targetUserId}`);

      get(followRef).then((snapshot) => {
        if (snapshot.exists()) {
          setIsFollowing(true);
        }
        setLoading(false);
      }).catch((error) => {
        console.error("Error checking follow status: ", error);
        setLoading(false);
      });
    }
  }, [currentUser, targetUserId]);

  const handleFollow = async () => {
    if (currentUser) {
      const db = getDatabase();
      const followRef = dbRef(db, `follows/${currentUser.uid}/${targetUserId}`);
      const followerRef = dbRef(db, `followers/${targetUserId}/${currentUser.uid}`);
      const userRef = dbRef(db, `users/${currentUser.uid}`);

      try {
        await set(followRef, true);
        await set(followerRef, true);
        await update(userRef, { [`following/${targetUserId}`]: true });
        setIsFollowing(true);
      } catch (error) {
        console.error("Error following user: ", error);
      }
    }
  };

  const handleUnfollow = async () => {
    if (currentUser) {
      const db = getDatabase();
      const followRef = dbRef(db, `follows/${currentUser.uid}/${targetUserId}`);
      const followerRef = dbRef(db, `followers/${targetUserId}/${currentUser.uid}`);
      const userRef = dbRef(db, `users/${currentUser.uid}`);

      try {
        await set(followRef, null);
        await set(followerRef, null);
        await update(userRef, { [`following/${targetUserId}`]: null });
        setIsFollowing(false);
      } catch (error) {
        console.error("Error unfollowing user: ", error);
      }
    }
  };

  if (loading) {
    return <button disabled>Loading...</button>;
  }

  return (
    <button onClick={isFollowing ? handleUnfollow : handleFollow}>
      {isFollowing ? 'Unfollow' : 'Follow'}
    </button>
  );
};

export default FollowButton;