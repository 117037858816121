import React, { useRef, useState, useCallback, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, query, orderByChild, equalTo, get } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import './BarcodeScanner.css';

const BarcodeScanner = () => {
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [error, setError] = useState(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const codeReaderRef = useRef(null);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  const handleReturnToSearchPage = () => {
    navigate('/home-search');
  };

  const initializeCodeReader = useCallback(() => {
    if (!codeReaderRef.current) {
      codeReaderRef.current = new BrowserMultiFormatReader();
    }
  }, []);

  const processBarcode = (barcode) => {
    return barcode.trim().replace(/[^0-9]/g, '');
  };

  const handleScan = useCallback(async (result) => {
    if (result) {
      const barcode = result.getText();
      console.log('Scanned Barcode:', barcode);
      console.log('Barcode Type:', result.getBarcodeFormat());

      const processedBarcode = processBarcode(barcode);
      console.log('Processed Barcode:', processedBarcode);

      try {
        const db = getDatabase(app);
        const productsRef = ref(db, 'nature/products');
        const productQuery = query(productsRef, orderByChild('barcode'), equalTo(processedBarcode));

        const snapshot = await get(productQuery);
        if (snapshot.exists()) {
          const products = snapshot.val();
          const productId = Object.keys(products)[0];
          navigate(`/product/${productId}`);
        } else {
          navigate(`/add-product?barcode=${processedBarcode}`);
        }
      } catch (error) {
        console.error('Error processing barcode:', error);
        setError('Error processing barcode. Please try again.');
      }

      // Stop scanning after successful scan
      if (codeReaderRef.current) {
        codeReaderRef.current.reset();
      }
      setIsScanning(false);
    }
  }, [navigate]);

  const startScanning = useCallback(async () => {
    if (!isVideoReady) {
      console.log("Video not ready yet");
      return;
    }
    try {
      initializeCodeReader();
      const videoElement = videoRef.current;
      const codeReader = codeReaderRef.current;

      setIsScanning(true);
      await codeReader.decodeFromVideoDevice(null, videoElement, handleScan);

      console.log("Decoding started");
    } catch (error) {
      console.error('Error accessing camera:', error);
      setError('Error accessing camera: ' + error.message);
    }
  }, [isVideoReady, initializeCodeReader, handleScan]);

  const initializeCamera = useCallback(async () => {
    if (streamRef.current) {
      console.log("Camera already initialized");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: "environment" } 
      });
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play().catch(e => {
          if (e.name !== "AbortError") {
            throw e;
          }
          // If it's an AbortError, we'll just log it and continue
          console.log("Play aborted, likely due to multiple play attempts");
        });
        console.log("Rear camera stream set to video element");
        setIsVideoReady(true);
      }
    } catch (err) {
      console.error("Error accessing rear camera:", err);
      setError("Error accessing camera: " + err.message);
    }
  }, []);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        initializeCamera();
      } else {
        navigate('/login', { state: { from: '/barcode-scanner' } });
      }
    });

    return () => {
      unsubscribe();
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }
      if (codeReaderRef.current) {
        codeReaderRef.current.reset();
      }
    };
  }, [navigate, initializeCamera]);

  useEffect(() => {
    if (isVideoReady && !isScanning) {
      startScanning();
    }
  }, [isVideoReady, isScanning, startScanning]);

  if (!currentUser) {
    return null; // or a loading indicator
  }

  return (
    <div className="barcode-scanner">
      <div className="scanner-content">
        <h2>Scan Barcode for Database Search and to Add Item</h2>
        <h3>If an item is found, it will display it, if not you can add a new item</h3>
        {error && <p className="error-message">{error}</p>}
        <video 
          ref={videoRef} 
          autoPlay 
          playsInline 
          className="scanner-video"
        />
        {!isVideoReady && <p>Initializing camera...</p>}
        <button onClick={handleReturnToSearchPage}>
          Return to Search Page
        </button>
      </div>
    </div>
  );
};

export default BarcodeScanner;
