import { useCallback } from 'react';

export const useAppBadge = () => {
  const setAppBadge = useCallback((count) => {
    if ('setAppBadge' in navigator) {
      navigator.setAppBadge(count).then(() => {
        console.log('Badge updated successfully');
      }).catch(error => {
        console.error('Error updating badge:', error);
      });
    } else {
      console.log('Badge API not supported');
    }
  }, []);

  const clearAppBadge = useCallback(() => {
    if ('clearAppBadge' in navigator) {
      navigator.clearAppBadge().then(() => {
        console.log('Badge cleared successfully');
      }).catch(error => {
        console.error('Error clearing badge:', error);
      });
    } else {
      console.log('Badge API not supported');
    }
  }, []);

  return { setAppBadge, clearAppBadge };
};