import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Notifications.css';
import { useAppBadge } from './hooks/useAppBadge';

const Notifications = ({ onNewNotification, onNewComment }) => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getDatabase();
  const prevNotificationsRef = useRef();
  const { setAppBadge, clearAppBadge } = useAppBadge();

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const notificationsRef = ref(db, `notifications/${user.uid}`);
      const unsubscribe = onValue(notificationsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const notificationList = Object.entries(data).map(([id, notification]) => ({
            id,
            ...notification,
          }));
          
          notificationList.sort((a, b) => b.timestamp - a.timestamp);
          
          const uniqueNotifications = notificationList.reduce((acc, current) => {
            const x = acc.find(item => item.type === current.type && item.postId === current.postId);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          
          setNotifications(uniqueNotifications);
          const unreadCount = uniqueNotifications.filter(notification => !notification.read).length;
          onNewNotification(unreadCount);
          setAppBadge(unreadCount);
          
          prevNotificationsRef.current = uniqueNotifications;
        } else {
          setNotifications([]);
          onNewNotification(0);
          clearAppBadge();
        }
      });

      return () => unsubscribe();
    }
  }, [auth.currentUser, db, onNewNotification, setAppBadge, clearAppBadge]);

  const handleNotificationClick = (notification) => {
    const user = auth.currentUser;
    if (user) {
      const notificationRef = ref(db, `notifications/${user.uid}/${notification.id}`);
      remove(notificationRef)
        .then(() => {
          console.log('Notification removed');
          
          if (notification.type === 'newPost') {
            navigate(`/blog#${notification.postId}`);
          } else if (notification.type === 'newComment') {
            navigate(`/blog#${notification.postId}-${notification.commentId}`);
            onNewComment(notification.commentId); // Call the function here
          } else if (notification.type === 'newReview') {
            navigate(`/product/${notification.productId}`);
          }
  
          const updatedNotifications = notifications.filter(n => n.id !== notification.id);
          setNotifications(updatedNotifications);
          const unreadCount = updatedNotifications.filter(n => !n.read).length;
          onNewNotification(unreadCount);
          setAppBadge(unreadCount);
        })
        .catch((error) => {
          console.error('Error removing notification:', error);
        });
    }
  };

  const showNotification = (message) => {
    if (Notification.permission === 'granted') {
      new Notification(message);
    }
  };

  useEffect(() => {
    notifications.forEach(notification => {
      if (!notification.read) {
        showNotification(notification.message);
      }
    });
  }, [notifications]);

  return (
    <div className="notifications-container">
      <h1>Notifications</h1>
      {notifications.length === 0 ? (
        <p>No notifications</p>
      ) : (
        notifications.map((notification) => (
          <div
            key={notification.id}
            className={`notification ${notification.read ? '' : 'unread'}`}
            onClick={() => handleNotificationClick(notification)}
          >
            <p>{notification.message}</p>
            <small>{new Date(notification.timestamp).toLocaleString()}</small>
          </div>
        ))
      )}
    </div>
  );
};

export default Notifications;
