import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import app from './firebaseConfig';
import './Restaurants.css';
import TopNavBar from './TopNavBar';
import StarRating from './StarRating';

const Restaurants = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase(app);
    const restaurantsRef = ref(db, 'restaurants');

    onValue(restaurantsRef, (snapshot) => {
      const data = snapshot.val();
      const allRestaurants = [];

      for (const restaurantId in data) {
        const restaurant = data[restaurantId];
        let averageRating = 0;
        let reviewCount = 0;
        if (restaurant.reviews) {
          const ratings = Object.values(restaurant.reviews).map((review) => Number(review.rating));
          averageRating = ratings.reduce((a, b) => a + b, 0) / ratings.length;
          reviewCount = ratings.length;
        }
        allRestaurants.push({ ...restaurant, restaurantId, averageRating, reviewCount, favritzedCount: restaurant.favritzedCount || 0 });
      }

      setRestaurants(allRestaurants);
      setIsLoading(false);
    }, (error) => {
      console.error('Error fetching data:', error);
      setError(error);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading restaurants: {error.message}</div>;
  }

  return (
    <div className="restaurants-page">
      <TopNavBar />
      <h2>Restaurants</h2>
      <button className="add-restaurant-button" onClick={() => navigate('/add-restaurant')}>
        Add Restaurant
      </button>
      <div className="restaurants-list">
        {restaurants.map((restaurant) => (
          <div
            key={restaurant.restaurantId}
            className="restaurant-card"
            onClick={() => navigate(`/restaurant-detail/${restaurant.restaurantId}`)}
          >
            {restaurant.imageUrl ? (
              <img src={restaurant.imageUrl} alt={restaurant.name} />
            ) : (
              <div className="no-image">No picture available</div>
            )}
            <h3>{restaurant.name}</h3>
            <p>{restaurant.location}</p>
            <StarRating rating={restaurant.averageRating} readonly size={15} />
            <p>{restaurant.reviewCount} review{restaurant.reviewCount !== 1 ? 's' : ''}</p>
            <p>Favritzed {restaurant.favritzedCount} time{restaurant.favritzedCount !== 1 ? 's' : ''}</p>
            <p>Added by: {restaurant.addedByUsername}</p>
            <p>Date Added: {new Date(restaurant.dateAdded).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Restaurants;
