import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';
import { getStorage, ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from './firebaseConfig';
import './EditRecipe.css';

const EditRecipe = () => {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [name, setName] = useState('');
  const [ingredients, setIngredients] = useState(['']);
  const [instructions, setInstructions] = useState(['']);
  const [image, setImage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [addedBy, setAddedBy] = useState(null);
  const [originalImageUrl, setOriginalImageUrl] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate('/login', { state: { from: `/edit-recipe/${recipeId}` } });
      }
    });

    return () => unsubscribe();
  }, [navigate, recipeId]);

  useEffect(() => {
    const db = getDatabase(app);
    const recipeRef = ref(db, `recipes/${recipeId}`);

    get(recipeRef).then((snapshot) => {
      if (snapshot.exists()) {
        const recipe = snapshot.val();
        setName(recipe.name);
        setIngredients(recipe.ingredients);
        setInstructions(recipe.instructions);
        setImage(recipe.imageUrl);
        setOriginalImageUrl(recipe.imageUrl);
        setAddedBy(recipe.addedBy);
      } else {
        alert('Recipe not found');
        navigate('/recipes');
      }
    }).catch((error) => {
      console.error('Error fetching recipe:', error);
      alert('Error fetching recipe');
    });
  }, [recipeId, navigate]);

  const handleIngredientChange = (index, value) => {
    const newIngredients = [...ingredients];
    newIngredients[index] = value;
    setIngredients(newIngredients);
  };

  const handleInstructionChange = (index, value) => {
    const newInstructions = [...instructions];
    newInstructions[index] = value;
    setInstructions(newInstructions);
  };

  const addIngredient = () => {
    setIngredients([...ingredients, '']);
  };

  const addInstruction = () => {
    setInstructions([...instructions, '']);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      alert('You must be logged in to edit a recipe.');
      navigate('/login', { state: { from: `/edit-recipe/${recipeId}` } });
      return;
    }

    if (currentUser.uid !== addedBy) {
      alert('You are not authorized to edit this recipe.');
      return;
    }

    const db = getDatabase(app);
    const storage = getStorage(app);
    const recipeRef = ref(db, `recipes/${recipeId}`);

    try {
      let imageUrl = originalImageUrl;
      if (image && image.startsWith('data:image/')) {
        const imageRef = storageRef(storage, `recipe-images/${Date.now()}.jpg`);
        await uploadString(imageRef, image, 'data_url');
        imageUrl = await getDownloadURL(imageRef);
      }

      const updatedRecipe = {
        name,
        ingredients: ingredients.filter(ingredient => ingredient.trim() !== ''),
        instructions: instructions.filter(instruction => instruction.trim() !== ''),
        imageUrl,
      };

      await update(recipeRef, updatedRecipe);
      alert('Recipe updated successfully!');
      navigate(`/recipe/${recipeId}`);
    } catch (error) {
      console.error('Error updating recipe:', error);
      alert(`Failed to update recipe. Error: ${error.message}`);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!currentUser) {
    return null; // or a loading indicator
  }

  return (
    <div className="edit-recipe">
      <h2>Edit Recipe</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Recipe Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        
        <h3>Ingredients</h3>
        {ingredients.map((ingredient, index) => (
          <input
            key={index}
            type="text"
            placeholder={`Ingredient ${index + 1}`}
            value={ingredient}
            onChange={(e) => handleIngredientChange(index, e.target.value)}
          />
        ))}
        <button type="button" onClick={addIngredient}>Add Ingredient</button>
        
        <h3>Instructions</h3>
        {instructions.map((instruction, index) => (
          <input
            key={index}
            type="text"
            placeholder={`Step ${index + 1}`}
            value={instruction}
            onChange={(e) => handleInstructionChange(index, e.target.value)}
          />
        ))}
        <button type="button" onClick={addInstruction}>Add Instruction</button>
        
        {!image && (
          <>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <button type="button" onClick={() => fileInputRef.current.click()}>Upload Picture</button>
          </>
        )}
        
        {image && (
          <div className="preview">
            <img src={image} alt="Recipe" />
            <button type="button" onClick={() => setImage(null)}>Remove Image</button>
          </div>
        )}
        
        <button type="submit">Update Recipe</button>
        <button type="button" onClick={() => navigate('/recipes')}>
          Back to All Recipes
        </button>
      </form>
    </div>
  );
};

export default EditRecipe;
